import {
    Container,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';


const ProfilePlaceholder = () => {

    return (
        <>
            <Container maxWidth={false}>
                <Container maxWidth="xl">
                    <Typography variant='h6' component="h4" sx={{ marginBottom: '16px' }}>
                        <Skeleton width="40%" />
                    </Typography>
                    <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="skeleton table" size="small">
                            <TableBody>
                                {[...Array(6)].map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell scope="row" sx={{ width: '200px' }}>
                                            <Skeleton width="80%" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton width="60%" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Container>
        </>
    )
}

export default ProfilePlaceholder;