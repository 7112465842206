
import { AuthenticationResult } from '@azure/msal-browser';
import { userPermissionScopes } from "../AuthConfig";
import { ActivityLogEntry, Dropdowns, EmbedTokenResult, ExportFileRequest, MangeUsersDTO, MangeUsersDTOV2, PermissionRequest, ReportSorting, TenantIndicators, TenantManagementInfo, Tickets, User, UserReports, ValidationPermissionsRequest, ViewModel } from '../models/models';
import { msalInstance } from './../index';

const ApiRoot: string = process.env.REACT_APP_API_BASE_URL;

const GetAccessToken = async (): Promise<string> => {
    const account = msalInstance?.getActiveAccount();

    if (account) {
        let authResult: AuthenticationResult;
        try {
            // try to acquire access token from MSAL cache first
            authResult = await msalInstance.acquireTokenSilent({ scopes: userPermissionScopes, account: account });
        }
        catch {
            // if access token not available in cache, interact with user to acquire new access token 
            authResult = await msalInstance.acquireTokenPopup({ scopes: userPermissionScopes, account: account });
        }
        // return access token from authnetication result 
        return authResult.accessToken;
    }
    else {
        return "";
    }
};

export const LoginUser = async (LoginId: string, UserName: string): Promise<void> => {

    var user = new User();
    user.LoginId = LoginId;
    user.UserName = UserName;

    var accessToken: string = await GetAccessToken();
    var postData: string = JSON.stringify(user);

    var restUrl = ApiRoot + "UserLogin/";

    await fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    })
    return;
}


export const GetEmbeddingData = async (): Promise<ViewModel> => {

    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "Embed/";

    return fetch(restUrl, {
        method: "GET",
        headers: {
            "Accept": "application/json;",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
}

export const GetEmbedToken = async (): Promise<EmbedTokenResult> => {

    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "EmbedToken/";

    return fetch(restUrl, {
        method: "GET",
        headers: {
            "Accept": "application/json;",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
}

export const GetUserReports = async (UserName: string): Promise<UserReports> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/";
    var user = new User();
    user.UserName = UserName;
    var postData: string = JSON.stringify(user);

    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then((response: UserReports) => {
            for (var i = 0; i < response.allReports.length; i++) {
                var rep = response.assignedReports.find(x => x.reportID == response.allReports[i].reportID);
                if (rep)
                    response.allReports[i]["isAssigned"] = true
                else
                    response.allReports[i]["isAssigned"] = false
            }
            return response
        });
    return fetchResponse;
}

export const UpdateUserReports = async (userName: string, assignedReportIds: string[]): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateReportMapping/" + userName;
    var permissionRequest = new PermissionRequest();
    permissionRequest.reports = assignedReportIds;
    var postData: string = JSON.stringify(permissionRequest);
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const IsValidUser = async (userName: string, permissionsToCheck: string[]): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/CheckUserPermissions/";
    var permissionRequest = new ValidationPermissionsRequest();
    permissionRequest.permissionsToCheck = permissionsToCheck;
    permissionRequest.userToCheck = userName;
    var postData: string = JSON.stringify(permissionRequest);
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const GetUsers = async (UserName?: string): Promise<MangeUsersDTO> => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/GetUsers";
        if (UserName)
            restUrl += "/" + UserName;

        const response = await fetch(restUrl, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        });

        if (!response.ok) {
            throw new Error("Failed to fetch user data.");
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error("Error fetching user data:", error);
    }
}

export const GetUsersAdmin = async (UserName?: string): Promise<MangeUsersDTOV2> => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/GetUsersAdmin";
        if (UserName)
            restUrl += "/" + UserName;

        const response = await fetch(restUrl, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        });

        if (!response.ok) {
            throw new Error("Failed to fetch user data.");
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error("Error fetching user data:", error);
    }
}


export const ToggleUserRights = async (userName: string, rightType: string): Promise<boolean> => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/UpdateUserRights/" + userName;
        var premission = new PermissionRequest();
        premission.right = rightType;
        var postData: string = JSON.stringify(premission);

        const response = await fetch(restUrl, {
            method: "POST",
            body: postData,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        });

        if (!response.ok) {
            throw new Error("Failed to fetch user data.");
        }
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error("Error fetching user data:", error);
    }
}

export const LogActivity = async (activityLogEntry: ActivityLogEntry): Promise<void> => {

    var accessToken: string = await GetAccessToken();
    var postData: string = JSON.stringify(activityLogEntry);
    var restUrl = ApiRoot + "ActivityLog/";

    let fetchResponse = await fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    });

    return;

}

export const ExportFile = async (ExportRequest: ExportFileRequest): Promise<void> => {

    var restUrl: string = ApiRoot + "ExportFile/";
    var accessToken: string = await GetAccessToken();

    // prepare JSON body for POST request to retrieve exported report file
    var postData: string = JSON.stringify(ExportRequest);

    // execute POST request synchronously to retrieve exported report file
    let fetchResponse = await fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    });

    // Once POST call returns, get file name from HTTP response
    const header = fetchResponse.headers.get('Content-Disposition');
    const parts = header!.split(';');
    let filename = parts[1].split('=')[1];

    // get blob with export file content
    let blob = await fetchResponse.blob();

    // trigger export file download in browser window
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();

    // return control to caller using await
    return;
}

export const UpdateUserTenant = async (userName: string, tenantName: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateUserTenant/" + userName + "/" + tenantName;
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const UpdateUserReportLanguageCode = async (loginId: string, reportLangCode: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateReportLangCode/";
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: JSON.stringify({
            LoginId: loginId,
            LanguageCode: reportLangCode
        }),
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const UpdateUserReportRole = async (loginId: string, reportRoleId: number): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateUserReportRole/";
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: JSON.stringify({
            loginId,
            reportRoleId
        }),
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const UpdateUserDefaultReport = async (userName: string, reportId: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateUserDefautReport/" + userName + "/" + reportId;
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const UpdateReportSortOrder = async (tenantName: string, reportOrder: ReportSorting[]): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateReportSortOrder/" + tenantName;
    var postData: string = JSON.stringify(reportOrder);
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const UpdateTenantDefaultReport = async (tenantName: string, reportId: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UpdateTenantsDefautReport/" + tenantName + "/" + reportId;
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const ResetTheme = async (tenantName: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/ResetTenantTheme/" + tenantName;
    let fetchResponse = fetch(restUrl, {
        method: "GET",
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const ResetOneOffTheme = async (tenantName: string): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/ResetOneOffTenantTheme/" + tenantName;
    let fetchResponse = fetch(restUrl, {
        method: "GET",
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const SaveTheme = async (tenantName: string, themeInfo: any): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/SaveTenantTheme/" + tenantName;
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: JSON.stringify(themeInfo),
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const SaveOneOffTheme = async (tenantName: string, oneOffThemeInfo: any): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/SaveOneOffTenantTheme/" + tenantName;
    let fetchResponse = fetch(restUrl, {
        method: "POST",
        body: JSON.stringify(oneOffThemeInfo),
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}


export const GetTenantThemeAndModules = async (tenantName: string): Promise<any> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/GetTenantThemeAndModules/" + tenantName;
    let fetchResponse = fetch(restUrl, {
        method: "GET",
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => {
        if (!response.ok) {
            throw new Error("Network response");
        }
        return response;
    }).then(response => {
        if (response.status === 204) {
            return null;
        }
        return response.json();
    }).catch(error => {
        console.error("Error fetching data:", error);
        throw error;
    });
    return fetchResponse;
}

export const GetTenantManagementInfo = async (userName: string, tenantName: string): Promise<TenantManagementInfo> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/GetTenantManagementInfo/" + tenantName;
    var userRequest = new PermissionRequest();
    var postData: string = JSON.stringify(userRequest);

    let fetchResponse = await fetch(restUrl, {
        method: "POST",
        body: postData,
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    });

    if (!fetchResponse.ok) {
        throw new Error("Request failed");
    }

    let responseJson = await fetchResponse.json();
    return responseJson;
}

export const UploadTenantLogo = async (tenantName: string, formaData: FormData): Promise<boolean> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/UploadTenantLogo/" + tenantName;
    let fetchResponse = await fetch(restUrl, {
        method: "POST",
        body: formaData,
        headers: {
            "Accept": "application/json;",
            "Authorization": "Bearer " + accessToken
        }
    });

    if (!fetchResponse.ok) {
        throw new Error("Request failed");
    }

    let responseJson = await fetchResponse.json();
    return responseJson;
}

export const GetTenantsIndicatorsData = async (): Promise<TenantIndicators[]> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/GetTenantsIndicators";
    let fetchResponse = fetch(restUrl, {
        method: "GET",
        headers: {
            "Accept": "application/json;",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => response.json())
        .then(response => response);
    return fetchResponse;
}

export const GetTenantLogo = async (tenantName: string): Promise<Uint8Array> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "UserManagement/GetTenantLogo/" + tenantName;
    let fetchResponse = fetch(restUrl, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + accessToken
        }
    }).then(response => {
        return response.arrayBuffer()
    }).then(arrayBuffer => new Uint8Array(arrayBuffer));
    return fetchResponse;
}

export const CreateNewUser = async (loginId: string, userName: string, loggedInUsersId: string, setDefaultTenant: boolean) => {
    try {
        var accessToken = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/CreateNewUser";

        let response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            },
            body: JSON.stringify({
                LoginId: loginId,
                UserName: userName,
                LoggedInUsersId: loggedInUsersId,
                SetDefaultTenant: setDefaultTenant
            })
        });

        if (response.ok) {
            return "USER CREATED";
        } else if (response.status === 409) {
            return "USER ALREADY EXISTS";
        } else {
            // Handle other status codes
            let errorMessage = await response.text();
            throw new Error(errorMessage);
        }
    } catch (error) {
        return "AN INTERNAL ERROR OCCURED";
    }
}

export const GetDropdowns = async (): Promise<Dropdowns[]> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "SupportRequests/GetDropdowns";

    const response = await fetch(restUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        },
    });

    if (!response.ok) {
        console.error(`An Error Occurred while fetching Dropdown Data! Status: ${response.status} `);
        throw new Error(`An Error Occurred while fetching Dropdown Data! Status: ${response.status} `);
    }

    const responseData = await response?.json();
    return responseData;
}

export const GetTickets = async (loggedInUserId?: string, selectedTenant?: string): Promise<Tickets[]> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "SupportRequests/GetSupportTickets/";
    const response = await fetch(restUrl, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        },
        body: JSON.stringify({
            callingUser: loggedInUserId || '',
            selectedTenant: selectedTenant || ''
        }),
    });

    if (!response.ok) {
        console.error(`An Error Occurred while fetching Tickets Data! Status: ${response.status}`);
        throw new Error(`An Error Occurred while fetching Tickets Data! Status: ${response.status}`);
    }

    const data = await response?.json();
    return data;
}

export const GetTicketsV2 = async (page: number, perPage: number, selectedAssignedViewFilter: string): Promise<any> => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "SupportRequests/GetSupportTicketsV2?page=" + page + "&per_page=" + perPage + "&filterView=" + selectedAssignedViewFilter;
    const response = await fetch(restUrl, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        },
    });

    if (!response.ok) {
        console.error(`An Error Occurred while fetching Tickets Data! Status: ${response.status}`);
        throw new Error(`An Error Occurred while fetching Tickets Data! Status: ${response.status}`);
    }

    const data = await response?.json();
    return data;
}

export const GetTenantUsers = async (selectedTenant: string) => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "SupportRequests/GetTenantUsersByTenant";

    const response = await fetch(restUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
        },
        body: JSON.stringify({
            tenantName: selectedTenant
        }),
    });

    if (!response.ok) {
        console.error(`An Error Occurred while fetching Dropdown Data! Status: ${response.status} `);
        throw new Error(`An Error Occurred while fetching Dropdown Data! Status: ${response.status} `);
    }

    const responseData = await response?.json();
    return responseData;
}

export const UpsertTicket = async (loginId: string, currentRequestDetails: any): Promise<number> => {
    try {
        const accessToken: string = await GetAccessToken();
        const restUrl = ApiRoot + "SupportRequests/UpsertTicket";

        var requestBody: any = {
            title: currentRequestDetails.title,
            description: currentRequestDetails.description,
            priorityId: currentRequestDetails.priorityId,
            requestTypeId: currentRequestDetails.requestTypeId,
            reportReference: currentRequestDetails.reportReference,
            reportPage: currentRequestDetails.reportPageReference,
            statusNoteCustomer: currentRequestDetails.statusNoteCustomer,
            statusNoteDBIZ: currentRequestDetails.statusNoteDBIZ,
            notesInternalDBIZ: currentRequestDetails.notesInternalDBIZ,
            hourEstimatesDBIZ: currentRequestDetails.hourEstimatesDBIZ,
            requestCostId: currentRequestDetails.requestCostId,
            assignedToUserId: currentRequestDetails.assignedToUserId,
            customerApproverUserId: currentRequestDetails.customerApproverUserId,
            completedByUserId: currentRequestDetails.completedByUserId,
            statusId: currentRequestDetails.statusId,
            plannedDueDate: currentRequestDetails.plannedDueDate,
            completedDate: currentRequestDetails.completedDate,
            screenType: currentRequestDetails.screenType,
            selectedTenant: currentRequestDetails.selectedTenant,
            callingUser: loginId,
        };

        if (currentRequestDetails.ticketID) {
            requestBody = {
                ...requestBody,
                ticketID: currentRequestDetails.ticketID,
            };
        }

        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error(`Failed to create/update a ticket.Status: ${response.status} `);
        }

        const jsonResponse: number = await response.json();
        return jsonResponse;
    } catch (error) {
        throw error;
    }
}

export const DeleteAttachmentById = async (attachmentId: number) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "SupportRequests/DeleteAttachment/" + attachmentId;

        const response = await fetch(restUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to delete the attachment ticket.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
        throw error;
    }
}

export const ToggleIsApprovalNeeded = async (username: string, requestId: number) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "SupportRequests/ToogleIsApprovalNeeded";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify({
                callingUser: username,
                suppoerRequestId: requestId
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to update the Approval Needed Flag.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}

export const UpdateRequestModulePermission = async (selectedTenant: string, requestModulePermission: boolean) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "SupportRequests/UpdateRequestModulePermission";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify({
                selectedTenant,
                requestModulePermission
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to update the Module Permission Flag.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}


export const DeleteUser = async (selectedUserToDelete: string) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/DeleteUser/" + selectedUserToDelete;
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to delete the User.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}

export const AddNewReportRole = async (selectedTenant: string, newRole: string) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/AddTenantRole/";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify({
                tenantName: selectedTenant,
                role: newRole
            })
        });

        if (!response.ok) {
            throw new Error(`Failed to add new role.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}

export const EditReportRole = async (roleId: number, role: string) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/EditTenantRole";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify({
                role,
                roleId
            })
        });

        if (!response.ok) {
            throw new Error(`Failed to add new role.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}


export const DeleteReportRole = async (roleId: number) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "UserManagement/DeleteTenantRole/";
        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify({
                roleId
            })
        });

        if (!response.ok) {
            throw new Error(`Failed to add new role.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;

    } catch (error) {
        throw error;
    }
}

export const ApproveTickets = async (username: string, selectedRowsForApproval: number[], approvalComment: string, selectedApprovalStatus: number) => {
    var accessToken: string = await GetAccessToken();
    var restUrl = ApiRoot + "SupportRequests/ApproveTickets";
    const response = await fetch(restUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken,
        },
        body: JSON.stringify({
            callingUser: username,
            selectedRowsForApproval: selectedRowsForApproval,
            approvalComment: approvalComment,
            selectedApprovalStatusId: selectedApprovalStatus
        }),
    });

    if (!response.ok) {
        throw new Error(`Failed to approve the ticket.Status: ${response.status}`);
    }

    const jsonResponse = await response.json();
    return jsonResponse;
}


export const AddAttachments = async (files: File[], supportTicketId: number) => {
    try {
        var accessToken: string = await GetAccessToken();
        var restUrl = ApiRoot + "SupportRequests/AddAttachments";
        const requestBody: any = []

        const filesData = files.map(async (file) => {
            const fileContent = await readFileContent(file);
            const base64Content = arrayBufferToBase64(fileContent);
            return {
                FileContent: base64Content,
                FileName: file.name,
                ContentType: file.type,
                SupportTicketId: supportTicketId
            };
        });

        await Promise
            .all(filesData)
            .then((fileObjects) => {
                requestBody.push(...fileObjects);
            })
            .catch((error) => {
                throw new Error("Error Reading Contents of the File!" + error);
            });

        if (requestBody.length === 0) {
            throw new Error("No Attachments Found");
        }

        const response = await fetch(restUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error(`Failed to add new Attachments.Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
        throw error;
    }
}


export const DownloadFile = async (attachmentId: number, fileName: string) => {
    try {
        var accessToken = await GetAccessToken();
        var restUrl = ApiRoot + "SupportRequests/DownloadAttachment/" + attachmentId;

        const response = await fetch(restUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to download attachment.Status: ${response.status}`);
        }

        const blob = await response.blob();

        // Creating a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        // Cleaning up the URL object
        window.URL.revokeObjectURL(link.href);

    } catch (error) {
        throw error;
    }
};

export const readFileContent = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result instanceof ArrayBuffer) {
                resolve(reader.result);
            } else {
                reject(new Error('Failed to read file content'));
            }
        };
        reader.readAsArrayBuffer(file);
    });
};

export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
};



