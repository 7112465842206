import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { AppContext } from "./AppContext";
import PageLayout from './components/PageLayout';
import { AssignedReports, PowerBiDataset, PowerBiReport } from './models/models';
import { GetEmbeddingData, GetTenantLogo, GetUserReports } from './services/AppOwnsDataWebApi';
import { MsalProvider } from "@azure/msal-react";

const App = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [tenantName, setTenantName] = useState<string>(null);
    const [pageLoadReportId, setPageLoadReportId] = useState('');
    const [reports, setReports] = useState<PowerBiReport[]>(null);
    const [datasets, setDatasets] = useState<PowerBiDataset[]>(null);
    const [user, setUser] = useState<string>(null);
    const [userCanEdit, setUserCanEdit] = useState<boolean>(null);
    const [userCanCreate, setUserCanCreate] = useState<boolean>(null);
    const [redirectUserToReport, setRedirectUserToReport] = useState<boolean>(null);
    const [workspaceArtifactsLoading, setWorkspaceArtifactsLoading] = useState<boolean>(null);
    const [displayMainPageLoader, setDisplayMainPageLoader] = useState<boolean>(false);
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [isDbizAdmin, setIsDbizAdmin] = useState(false);
    const [tenantLogo, setTenantLogo] = useState(null);
    const [assignedUserReports, setAssignedUserReports] = useState<AssignedReports[]>(null);
    const [defaultTenantReportId, setDefaultTenantReportId] = useState<string>('');
    const [tenantTheme, setTenantTheme] = useState<any>(null);
    const [reportLanguageCode, setReportLanguageCode] = useState<string>(null);
    const [isRequestModuleEnabled, setIsRequestModuleEnabled] = useState<boolean>(false);
    const [reportBackgroundColor, setReportBackgroundColor] = useState<string>(null);

    const refreshEmbeddingData = () => {
        const refreshEmbeddingDataAsync = async () => {
            let viewModel = await GetEmbeddingData();
            let userReports = await GetUserReports(accounts[0].username);
            let filteredReports = viewModel?.reports?.filter(x => userReports?.assignedReports?.map(y => y.reportID)?.indexOf(x.id) !== -1 && x.name?.substring(0, 1) != "0" && x.name?.substring(0, 6) != "Report" && x.name?.substring(0, 5) != "Usage");
            if (userReports.assignedReports.length > 0) {
                let assignedDefaultReport = userReports.assignedReports.filter(x => x.isDefaultReport === true);
                assignedDefaultReport.length > 0 ? setPageLoadReportId(assignedDefaultReport[0].reportID) : setPageLoadReportId(userReports.assignedReports[0].reportID);
            }
            setReports(filteredReports ? filteredReports : []);
        };
        refreshEmbeddingDataAsync();
    };

    useEffect(() => {
        const fetchLogoAndSetPageLoadReport = async () => {
            if (assignedUserReports && assignedUserReports.length > 0) {
                let assignedDefaultReport = assignedUserReports.filter(x => x.isDefaultReport === true);
                if (assignedDefaultReport.length > 0) {
                    setPageLoadReportId(assignedDefaultReport[0].reportID)
                } else {
                    if (defaultTenantReportId)
                        setPageLoadReportId(defaultTenantReportId);
                    else {
                        setPageLoadReportId(reports[0].id);
                    }
                }
            }
            setWorkspaceArtifactsLoading(false);
        };

        fetchLogoAndSetPageLoadReport();
    }, [reports])

    useEffect(() => {
        if (!isAuthenticated)
            return;

        const getEmbeddingDataAsync = async () => {
            setWorkspaceArtifactsLoading(true);
            let viewModel = await GetEmbeddingData();

            if (viewModel?.tenantThemeData) {
                setTenantTheme(JSON.parse(JSON.parse(viewModel?.tenantThemeData)?.ThemeInfo));
            }

            if (viewModel?.reportBackgroundColor) {
                setReportBackgroundColor(viewModel.reportBackgroundColor);
            }

            if (viewModel?.tenantName) {
                const response = await GetTenantLogo(viewModel?.tenantName);
                if (response != null && response.length > 0) {
                    const decompressedBlob = new Blob([response]);
                    const imageUrl = URL.createObjectURL(decompressedBlob);
                    setTenantLogo(imageUrl);
                }
            }
            let userReports = await GetUserReports(accounts[0].username);
            let filteredReports = viewModel?.reports?.filter(x => userReports?.assignedReports?.map(y => y.reportID)?.indexOf(x.id) !== -1 && x.name?.substring(0, 1) != "0" && x.name?.substring(0, 6) != "Report" && x.name?.substring(0, 5) != "Usage");
            setTenantName(viewModel.tenantName);
            setDefaultTenantReportId(viewModel.tenantDefaultId);
            setAssignedUserReports(userReports?.assignedReports || []);
            setReportLanguageCode(viewModel.reportLanguageCode);

            //Setting Report List Order
            if (viewModel.tenantReportSortOrder && viewModel.tenantReportSortOrder.length > 0) {
                if (filteredReports && filteredReports.length > 0) {
                    const reorderedCollection = filteredReports.sort((a, b) => {
                        const indexA = viewModel.tenantReportSortOrder.findIndex(item => item.reportId === a.id);
                        const indexB = viewModel.tenantReportSortOrder.findIndex(item => item.reportId === b.id);
                        return indexA - indexB;
                    });

                    setReports(reorderedCollection);
                }
                else
                    setReports(filteredReports || []);
            } else
                setReports(filteredReports || []);

            setDatasets(viewModel.datasets);
            setUser(accounts[0].name);
            setUserCanEdit(viewModel.userCanEdit);
            setUserCanCreate(viewModel.userCanCreate);
            setIsSuperUser(viewModel.isSuperUser);
            setIsDbizAdmin(viewModel.isDbizAdmin);
            setIsRequestModuleEnabled(viewModel.isRequestModuleEnabled);
            setRedirectUserToReport(true);
        }

        try {
            getEmbeddingDataAsync();
        }
        catch (e) {
            console.log("Exception", e);
        }

    }, [isAuthenticated]);

    return (
        <AppContext.Provider value={{
            embeddingData: {
                tenantName: tenantName,
                reports: reports,
                pageLoadReportId: pageLoadReportId,
                datasets: datasets,
                user: user,
                userCanEdit: userCanEdit,
                userCanCreate: userCanCreate,
                workspaceArtifactsLoading: workspaceArtifactsLoading,
                redirectUserToReport: redirectUserToReport,
                setRedirectUserToReport: setRedirectUserToReport,
                reportLanguageCode: reportLanguageCode,
                setReportLanguageCode: setReportLanguageCode,
                isRequestModuleEnabled: isRequestModuleEnabled,
            },
            refreshEmbeddingData: refreshEmbeddingData,
            setDisplayMainLoader: setDisplayMainPageLoader,
            displayMainLoader: displayMainPageLoader,
            isSuperUser: isSuperUser,
            isDbizAdmin: isDbizAdmin,
            tenantLogo: tenantLogo,
            tenantTheme: tenantTheme,
            reportBackgroundColor: reportBackgroundColor
        }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CssBaseline />
                <BrowserRouter>
                    <PageLayout />
                </BrowserRouter>
            </LocalizationProvider>
        </AppContext.Provider>
    )
}

export default App;