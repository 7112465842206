import InfoIcon from '@mui/icons-material/Info';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
    Box,
    Container,
    Skeleton,
    Tab, Tabs,
    Typography
} from '@mui/material';


const TenantManagementPlaceholder = () => {

    return (
        <>
            <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h5' component="h2" sx={{ mt: 2, mb: "6px", textAlign: 'center', fontWeight: '500px' }}>
                    <Skeleton width={200} />
                </Typography>
                <Tabs value={0} onChange={() => { }}>
                    <Tab label={<span style={{ color: '#0d4557' }}><Skeleton width={150} /></span>} />
                    <Tab label={<span style={{ color: '#0d4557' }}><VerifiedIcon style={{ verticalAlign: 'middle', marginRight: '4px', color: 'grey' }} /><Skeleton width={100} /></span>} />
                    <Tab label={<span style={{ color: '#0d4557' }}><VerifiedIcon style={{ verticalAlign: 'middle', marginRight: '4px', color: 'grey' }} /><Skeleton width={100} /></span>} />
                    <Tab label={<span style={{ color: '#0d4557' }}><VerifiedIcon style={{ verticalAlign: 'middle', marginRight: '4px', color: 'grey' }} /><Skeleton width={150} /></span>} />
                </Tabs>
                <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
                        <Typography variant="subtitle1" style={{ marginRight: '30px' }}>
                            <Skeleton width={250} />
                        </Typography>
                        <Skeleton variant="rectangular" width={300} height={35} sx={{ mr: 2 }} />
                        <Skeleton variant="rectangular" width={100} height={35} />
                    </div>
                    <div style={{
                        backgroundColor: 'transparent',
                        padding: '20px',
                        flex: 1,
                        borderRadius: '8px',
                        boxShadow: '0 5px 10px rgb(0 0 0 / 0.2)',
                        paddingBottom: '40px'
                    }}>
                        <Box display="flex" alignItems="center" marginBottom="10px">
                            <InfoIcon style={{ marginRight: '10px', color: '#333' }} />
                            <Typography variant="h6">
                                <Skeleton width={200} />
                            </Typography>
                        </Box>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Skeleton variant="rectangular" width={250} height={250} sx={{ mr: 2 }} />
                            <div>
                                <Skeleton variant="text" height={30} width={300} />
                                <Skeleton variant="text" height={30} width={300} />
                                <Skeleton variant="text" height={30} width={300} />
                                <Skeleton variant="text" height={30} width={300} />
                                <Skeleton variant="text" height={30} width={300} />
                                <Skeleton variant="text" height={30} width={300} />
                                <Skeleton variant="text" height={30} width={300} />
                                <Skeleton variant="text" height={30} width={300} />
                            </div>
                        </div>
                    </div>
                </Box>
            </Container>
        </>
    )
}

export default TenantManagementPlaceholder;