import {
    Container,
    Skeleton,
    Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, IconButton } from '@mui/material';

const ManagementPlaceholder = () => {

    return (
        <>
            <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h5' component="h2" sx={{ mt: 2, mb: "6px", textAlign: 'center', fontWeight: '500px' }}>
                    <Skeleton width={200} />
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 1 }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "4px" }}>
                            <IconButton sx={{ color: '#0d4557' }}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Skeleton variant="text" width={150} height={20} sx={{ ml: 1 }} />
                        </Box>
                        <Skeleton variant="rectangular" width="100%" height={400} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', height: '89vh' }}>
                        <Container sx={{ backgroundColor: '#f0f0f0', p: 2, boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);', height: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '10px', paddingBottom: '10px' }}>
                                <Typography variant="h6" sx={{ fontWeight: 'lighter' }}>
                                    <Skeleton width={100} />
                                </Typography>
                            </Box>
                            <Container
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '6px',
                                    boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);',
                                    marginBottom: '15px',
                                    borderLeft: '4px solid #00ffa9',
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'lighter' }}>
                                        <Skeleton width={100} />
                                    </Typography>
                                </Box>
                            </Container>
                            <Container
                                sx={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '6px',
                                    boxShadow: '0 5px 10px rgb(0 0 0 / 0.2);',
                                    borderLeft: '4px solid #00ffa9',
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '30px' }}>
                                    <Skeleton width="100%" height={40} />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: '30px', paddingBottom: '30px' }}>
                                    <Skeleton width="100%" height={40} />
                                </Box>
                            </Container>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Skeleton variant="rectangular" width="100%" height={36} sx={{ mt: 2 }} />
                                <Skeleton variant="rectangular" width="100%" height={36} sx={{ mt: 1 }} />
                                <Skeleton variant="rectangular" width="100%" height={36} sx={{ mt: 1 }} />
                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default ManagementPlaceholder;