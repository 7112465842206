import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { createRoot } from "react-dom/client";
import App from './App';
import { msalConfig } from "./AuthConfig";
import { LoginUser } from './services/AppOwnsDataWebApi';
import { IPublicClientApplication } from "@azure/msal-browser";

export var msalInstance: IPublicClientApplication = null;
let userLoginProcessed = false;

async function initializeMsal() {
    const msalInst = await PublicClientApplication.createPublicClientApplication(msalConfig);
    msalInstance = msalInst;

    const accounts = msalInst?.getAllAccounts();
    if (accounts && accounts.length > 0) {
        msalInst?.setActiveAccount(accounts[0]);
    }

    msalInst.addEventCallback(async (event: EventMessage) => {
        if ((msalInst?.getAllAccounts().length > 0) && event.payload &&
            (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInst?.setActiveAccount(account);
            if (!userLoginProcessed) {
                userLoginProcessed = true;
                await LoginUser(account.username, account.name);
            }
        }
    });

    return msalInst;
}


const root = createRoot(document.getElementById('root'));

(async () => {
    const msalInst = await initializeMsal();

    root.render(
        <MsalProvider instance={msalInst}>
            <App />
        </MsalProvider>
    );
})();
