import {
    Divider,
    List,
    ListItem,
    Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Suspense, lazy, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import Banner from './Banner';
import NotificationContainer from './NotificationContainer';
import Home from './pages/Home';
import ProfilePlaceholder from './placeholders/ProfilePlaceholder';
import ManagementPlaceholder from './placeholders/ManagementPlaceholder';
import TenantManagementPlaceholder from './placeholders/TenantManagementPlaceholder';

const Profile = lazy(() => import('./pages/Profile'));
const UserManagement = lazy(() => import('./pages/UserManagement'));
const TenantManagement = lazy(() => import('./pages/TenantManagement'));
const DBIZManagement = lazy(() => import('./pages/DBIZManagement'));
const UserRequest = lazy(() => import('./pages/UserRequest'));
const ApproverUI = lazy(() => import('./pages/ApproverUI'));
const DBIZTicketManagement = lazy(() => import('./pages/DBIZTicketManagement'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const LeftNav = lazy(() => import('./LeftNav'));
const MobileNav = lazy(() => import('./MobileNav'));
const Report = lazy(() => import('./pages/Report'));

const box1Styles = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
    maxHeight: '100vh'
}

const box2Styles = {
    flexGrow: 1,
    overflow: 'auto',
}

const PageLayout = () => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [leftNavExpanded, setLeftNavExpanded] = useState(false);

    return (
        <Box sx={box1Styles}>
            <NotificationContainer />
            <Banner setLeftNavExpanded={setLeftNavExpanded} />
            {isMobile &&
                <Suspense fallback={
                    <Box sx={{ width: 250, padding: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <Box sx={{ width: 40, height: 40, borderRadius: '50%', backgroundColor: '#ccc', marginRight: 2 }}></Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" sx={{ backgroundColor: '#ccc', height: 20, width: '70%' }}></Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ marginBottom: 2 }} />
                        <Box sx={{ marginBottom: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                                <Box sx={{ width: 40, height: 40, backgroundColor: '#ccc', marginRight: 2 }}></Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography variant="subtitle1" sx={{ backgroundColor: '#ccc', height: 20, width: '60%' }}></Typography>
                                </Box>
                            </Box>
                            <List disablePadding dense>
                                {[...Array(3)].map((_, index) => (
                                    <ListItem key={index} sx={{ backgroundColor: '#ccc', height: 40, marginBottom: 1, borderRadius: 1 }} />
                                ))}
                            </List>
                        </Box>
                        <Divider sx={{ marginBottom: 2 }} />
                    </Box>
                }>
                    <MobileNav />
                </Suspense>
            }
            {!isMobile &&
                <LeftNav setLeftNavExpanded={setLeftNavExpanded} leftNavExpanded={leftNavExpanded} />
            }
            <Box sx={box2Styles}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="reports/:id" element={
                        <Suspense><Report /></Suspense>
                    } />
                    <Route path="profile" element={
                        <Suspense fallback={<ProfilePlaceholder />}>
                            <Profile />
                        </Suspense>
                    } />
                    <Route path="usermanagement" element={
                        <Suspense fallback={<ManagementPlaceholder />}>
                            <UserManagement />
                        </Suspense>
                    } />
                    <Route path="tenantmanagement" element={
                        <Suspense fallback={<TenantManagementPlaceholder />}>
                            <TenantManagement />
                        </Suspense>
                    } />
                    <Route path="dbizmanagement" element={
                        <Suspense fallback={<ManagementPlaceholder />}>
                            <DBIZManagement />
                        </Suspense>
                    } />
                    <Route path="userrequest" element={
                        <Suspense fallback={<ManagementPlaceholder />}>
                            <UserRequest />
                        </Suspense>
                    } />
                    <Route path="requestapproval" element={
                        <Suspense fallback={<ManagementPlaceholder />}>
                            <ApproverUI />
                        </Suspense>
                    } />
                    <Route path="dbizreqmanagement" element={
                        <Suspense fallback={<ManagementPlaceholder />}>
                            <DBIZTicketManagement />
                        </Suspense>
                    } />
                    <Route path="*" element={
                        <Suspense>
                            <PageNotFound />
                        </Suspense>
                    } />
                </Routes>
            </Box >
        </Box >
    );
}

export default PageLayout;
